import React, { useState } from 'react';
import "./inputUI.scss"

const InputUI = ({ label, value, onChange, placeholder, type = 'text', error , classN=""}) => {
  const [visited,setVisited]=useState(false)
  const onChangeinp=(e)=>{
    if(e.target.value){
    setVisited(true)
    }else{
      setVisited(false)
    }
  
   if(onChange){
   onChange()

   }
  }
  return (
    <div className="input-group">
      {label && <label>{label}</label>}
      <input
        type={type}
        value={value}
        onChange={(e)=>onChangeinp(e)}
        placeholder={placeholder}
        className={`${classN} ${visited ? "visited" : ''}`}
      />
      {/* {error && <span className="error-message">{error}</span>} */}
    </div>
  );
};

export default InputUI;
