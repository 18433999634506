import React from 'react';
import useWindowResize from '../../hooks/useWindowResize';
import { CardUI } from '../../ui/CardUI';
import RoundedWrapperUI from '../../ui/RoundedWrapperUI';
import TitleUI from '../../ui/TitleUI';
import ToggleButtonUI from '../../ui/ToggleButtonUI';
import MulitiLineTextarea from '../../ui/MulitiLineTextarea';

export const SeoSettings = () => {
     const isMob=useWindowResize().width<1024
  return (
    <div className='list-pages'>
    <div className="tab-content seo-content">
        {isMob &&               <TitleUI  text="SEO" level="1" />}
      <RoundedWrapperUI className='maintance-mod-activation'>
        <TitleUI text="Индексация сайта" level="2" />
        <ToggleButtonUI /> 
      </RoundedWrapperUI>
      
      <div className='seo-cards-content'>
        <CardUI>
          <TitleUI text="htaccess" level="4" />
<MulitiLineTextarea firstLine="<Rewrite>" secondLine="..." thirdLine="</Rewrite>"/>
        </CardUI>

        <CardUI>
          <TitleUI text="robots.txt" level="4" />
          <MulitiLineTextarea firstLine="Dissallow" secondLine="..." thirdLine="/"/>

        </CardUI>

        <CardUI>
          <TitleUI text="Код метрики Яндекс/Google" level="4" />
          <MulitiLineTextarea firstLine="<script>" secondLine="..." thirdLine="</script>"/>

        </CardUI>
      </div>

   
      <div>
      </div>
    </div>
    </div>
  );
};
