import React, { useEffect, useState } from 'react'
import ButtonUI from '../../ui/ButtonUI';
import TabsContainer from '../../ui/TabsContent';
import { Content } from './Content';
import { Main } from './Main';
import { Seo } from './Seo';
import "./style.scss"
import {ReactComponent as Arrow } from "../../assets/svg/arrow.svg"
import { Link } from 'react-router-dom';
export const CreatePage = () => {
   const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("main");

  // The tabs array
  const tabs = [
    { label: 'Основное', tabName: "main" },
    { label: 'Содержание', tabName: "content" },
    { label: 'SEO', tabName: "seo" },
  ];
 // Check if the activeTab from localStorage is valid
 // Check if the activeTab from localStorage is valid
  useEffect(() => {
    const savedTabData = localStorage.getItem("activeTab");
    if (savedTabData) {
      const { activeTab: savedTab, activeIndex: savedIndex } = JSON.parse(savedTabData);

      // Validate if the savedTab exists in the tabs array
      const isValidTab = tabs.some(tab => tab.tabName === savedTab);

      if (isValidTab) {
        setActiveTab(savedTab);
        setActiveIndex(savedIndex);
      } else {
        // If invalid, default to the first tab
        setActiveTab(tabs[0].tabName);
        setActiveIndex(0);
      }
    }
  }, []);

  // Save the activeTab and activeIndex to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("activeTab", JSON.stringify({ activeTab, activeIndex }));
  }, [activeTab, activeIndex]);

  // Handle tab click
  const handleClick = (index, tab) => {
    setActiveIndex(index);
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'main':
        return (
          <Main/>
        );
      case 'content':
        return <Content/>;
      case 'seo':
        return <Seo/>;
      default:
        return null;
    }
  };
  return (
   <div className="create-page-container">
     <TabsContainer  renderContent={renderContent} titleText="Создание страницы" description="Создавайте ваши персональные анкеты, и распространяйте среди пользователей" backWard={true} tabs={tabs} handleClick={handleClick} activeIndex={activeIndex}>
        <Link to="/"><ButtonUI text="Назад" icon={Arrow} classN="backward-btn"/></Link> 
         <ButtonUI text="Сохарнить как черновик" classN="save-as-draft"/>
       </TabsContainer>
    </div>
  )
}
