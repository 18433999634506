import React from 'react'
import useWindowResize from '../../hooks/useWindowResize'
import { CardUI } from '../../ui/CardUI'
import InputUI from '../../ui/InputUI'
import TitleUI from '../../ui/TitleUI'
import ToggleButtonUI from '../../ui/ToggleButtonUI'
import WrapperUI from '../../ui/WrapperUI'

export const Seo = () => {
    const isMob=useWindowResize().width<1024
  return (
              <div className='list-pages'>

    <div className='tab-content'>
                      {isMob &&    <TitleUI  text="SEO" level="1" />}

       <div className='seo-cards'>
      <CardUI>
          <TitleUI text="H1" level="4" />
          <InputUI placeholder="SEO заголовок"/>
         
       </CardUI>
      <CardUI>
          <TitleUI text="Заколовок" level="4"/>
                    <InputUI placeholder="Meta Title"/>

      
       </CardUI>
      <CardUI>
          <TitleUI text="Описание " level="4"/>
                              <InputUI placeholder="Meta Description"/>

         
       </CardUI>
      <CardUI>
          <TitleUI text="Ключевые слова" level="4"/>
                                        <InputUI placeholder="Meta Description"/>

 
       </CardUI>
           </div>
       <CardUI classN="btn-container">
                   <TitleUI text="Роли" level="4"/>
    <div className='toggle-anwser'>
       <ToggleButtonUI isActive="false" text="Участвует в поиске"/>
       <ToggleButtonUI isActive="false" text="Участвует в sitemap"/>
       <ToggleButtonUI isActive="false" text="Участвует в sitemap"/>
       <ToggleButtonUI isActive="false" text="Участвует в поиске"/>
             <ToggleButtonUI isActive="false" text="Участвует в sitemap"/>

  
    </div>
       </CardUI>
    </div>
    </div>
  )
}
