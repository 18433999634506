import React from 'react'
import { CardUI } from '../../ui/CardUI'
import border from "../../assets/svg/borderCurved.svg"
import {    ReactComponent as Close } from "../../assets/svg/close.svg"
import TitleUI from '../../ui/TitleUI'
import InputUI from '../../ui/InputUI'
import SocialMediaSelector from '../../components/SocialMediaSelector'
import { IconWrapperUI } from '../../ui/IconWrapperUI'
import useWindowResize from '../../hooks/useWindowResize'
export const Contact = () => {
    const isMob=useWindowResize().width<1024
  return (  
     <div className='list-pages'>


    <div className='tab-content'>
                              {isMob &&    <TitleUI  text="Контакты" level="1" />}

     <div className='contact-tabs'>
           <div className='tabs-cart'>
                <CardUI classN="curved-block">
     <img src={border} alt="" />
     <div className='tabs-cart-block'>
<button><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.248 25.5V13.75H0.498047V12.25H12.248V0.499999H13.748V12.25H25.498V13.75H13.748V25.5H12.248Z" fill="#303030"/>
</svg></button>
<p>Вставить / Изменить </p>
<p>Логотип</p>

     </div>
            </CardUI>
            <IconWrapperUI bgColor="#EDBECE" classN='close-icon'>        <Close/>
             </IconWrapperUI>
      
   
           </div>
           <div className='tabs-cart'>
               <TitleUI text="Телефон" level="2"/>
        
           <CardUI>
               <TitleUI text="Телефон" level="4"/>
           <InputUI type='text' placeholder="+7 999 999 99 99"/>
               <TitleUI text="Ссылка" level="4"/>
                          <InputUI type='text' placeholder="+7 999 999 99 99"/>

            {/* <a href="tel:+999 999 99 99 ">tel: 999 999 99 99 </a> */}
           </CardUI>
              </div>
           <div className='tabs-cart'>
               <TitleUI text="Почта" level="2"/>
        
           <CardUI>
               <TitleUI text="Почта для обратной связи" level="4"/>
           <InputUI type='text' placeholder="1111tasde2@mail.ru"/>
               <TitleUI text="Почта дял сбора заявок" level="4"/>
                                         <InputUI type='text' placeholder="1111tasde2@mail.ru"/>
               {/* <a href="mailto:1111tasde2@mail.ru">1111tasde2@mail.ru</a> */}
           </CardUI>
              </div>
           <div className='tabs-cart social-media'>
                              <TitleUI text="Соц. сети" level="2"/>

           <CardUI>
               <SocialMediaSelector/>
          
           </CardUI>
              </div>
     </div>
    </div>
    </div>
  )
}
