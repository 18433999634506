import React from 'react'
import useWindowResize from '../../hooks/useWindowResize'
import { CardUI } from '../../ui/CardUI'
import CustomSelect from '../../ui/CustomSelectUI'
import DynamicInputUI from '../../ui/DynamicInputUI/DynamicInputUI'
 import InputUI from '../../ui/InputUI'
import TitleUI from '../../ui/TitleUI'
 
export const Main = () => {
  const isMob=useWindowResize().width<1024
  return (
        <div className='list-pages'>

    <div className='tab-content'>
              {isMob &&    <TitleUI  text="Основное" level="1" />}

      <CustomSelect placeholder="Главная"/>
       <div className='cards-content'>
      <CardUI>
 <div className='card-wrapper-content'>
            <TitleUI text="Название страницы" level="4" />
          <InputUI placeholder="Название страницы"/>
              
 </div>
        <div className='card-wrapper-content'>
            <TitleUI text="Символьный код" level="4" />
            <DynamicInputUI baseText="site.com/users/"/>
        </div>

          
       </CardUI>
      <CardUI>
    <div className='card-wrapper-content'>
            <TitleUI text="Анонс" level="4"/>
            <textarea name="" id="" cols="30" rows="10">Текстовый анонс или краткое описание</textarea>
    </div>
          <p>Не более 750 символов</p>
       </CardUI>
           </div>
    </div>
    </div>
  )
}
