import React, { useState } from 'react';
import './style.scss'; 

const MulitiLineTextarea = ({firstLine,secondLine,thirdLine}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="textarea-container">
      {/* Simulated placeholder */}
      {!isFocused && !inputValue && (
        <div className="custom-placeholder">
          <span>{firstLine}</span>
          <span>{secondLine} </span>
          <span>{thirdLine}</span>
        </div>
      )}

      {/* The actual textarea */}
      <textarea
        value={inputValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  );
};

export default MulitiLineTextarea;
