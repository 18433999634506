import React, { useState } from 'react';
import './style.scss'; // Import your SCSS styles if needed

const CustomSelect = ({ options, placeholder, icon, chevronWidth = 14, chevronHeight = 8 ,path=""}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-select" style={{ borderRadius: '30px', position: 'relative' }}>
      <div className="select-box" onClick={handleToggle}>
        <span className="selected-option">
          {selectedOption ? (
            <>
              {selectedOption.icon && (
                <>
                  <span className="icon">{selectedOption.icon}</span>
                  <span className="separator" />
                </>
              )}
              {!path && selectedOption.label}
            </>
          ) : (
            <>
              {icon && (
                <>
                  <span className="icon">{icon}</span>
                  <span className="separator" />
                </>
              )}
              {!path && placeholder}
            </>
          )}
        </span>
        <ChevronDownIcon width={chevronWidth} height={chevronHeight} />
      </div>
      {isOpen && (
        <ul className="options">
          {options?.length && options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              {option.icon && (
                <>
                  <span className="icon">{option.icon}</span>
                  {/* <span className="separator" /> */}
                </>
              )}
              {!path && option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const ChevronDownIcon = ( ) => (
  <span className="chevron-icon" style={{ display: 'inline-flex', alignItems: 'center' }}>
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L7 7L13 1" stroke="#1C597B" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  </span>

);

export default CustomSelect;
