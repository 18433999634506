import React, { useState } from 'react';
import './style.scss';  
function DynamicInput({baseText}) {
  const [userInput, setUserInput] = useState(''); 

  const handleInputChange = (event) => {
    const currentValue = event.target.value;

    if (!currentValue.startsWith(baseText)) {
      setUserInput('');  
    } else {
      const userInputPart = currentValue.slice(baseText.length);  
      setUserInput(userInputPart);
    }
  };

  const handleFocus = (event) => {
    // Place the cursor at the end of the baseText
    event.target.setSelectionRange(baseText.length, baseText.length);
  };

  return (
    <div className="input-container">
      {/* This div shows the styled text (grey for base, black for user input) */}
      <div className="styled-text">
        <span className="base-text">{baseText}</span>
        <span className="user-text">{userInput}</span>
      </div>
      {/* The actual input field, transparent for visual purposes */}
      <input
        type="text"
        value={baseText + userInput}
        onChange={handleInputChange}
        onFocus={handleFocus}
        className="transparent-input"
      />
    </div>
  );
}

export default DynamicInput;
